body {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-family: '-apple-system', 'Helvetica Neue', 'Helvetica', 'PingFang SC', 'Segoe UI',
        'SegoeUI', 'Microsoft YaHei', '微软雅黑', 'Noto Sans CJK SC', 'Arial', sans-serif !important ;
}
html,
body {
    min-width: 740px;
    overflow-x: auto;
    overflow-y: hidden;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
}

ol,
ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/**
 * Remove the border on images inside links in IE 10.
 */

img {
    border-style: none;
}

/* 文本超出...显示 */
.ellipsis {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/** NavLink */
.active {
    /* padding-bottom: 4px; */
    color: #006ee1 !important;
    font-weight: bold;
    /* border-bottom: 2px solid #006ee1; */
}

a {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
}

.pointer {
    cursor: pointer;
}

.ant-popover-arrow {
    display: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: color 99999s ease-out, background-color 99999s ease-out;
    transition-delay: 99999s;
}
::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset, 0 -1px 0 rgb(255 255 255 / 90%) inset,
        0 1px 0 rgb(255 255 255 / 90%) inset;
}
::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 8px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(400deg);
    }
}

.loading-icon {
    color: #006ee1 !important;
    font-size: 32px;
    animation: rotate 0.8s infinite linear;
}
.ant-spin-lg .ant-spin-dot {
    font-size: 32px !important;
}
.ant-select-item.ant-select-item-option.customItemList-option {
    display: none;
}

/** 全局设置antd 分页组件下拉箭头样式，沿用管理端的实现方式 */
.ant-pagination-options .ant-select-arrow {
    margin-top: -5px;
    font-size: 10px;
}

/** table 排序后对应的列元素不需要背景色 */
.ant-table-row td.ant-table-column-sort {
    background: none;
}
.widget-align-right {
    user-select: none;
}

/** Ant Empty */
.ant-empty .ant-empty-image {
    height: 64px;
}

.ant-empty.so-empty-table {
    padding: 22px 0;
}

.ant-empty.so-empty-large .ant-empty-image,
.ant-empty.so-empty-table .ant-empty-image {
    height: 120px;
}

/* 列表显示多时区样式 */
.tooltip-building-time-range {
    display: none;
}
.ant-tooltip-inner .tooltip-building-time-range {
    display: block;
}

.table-operation-header {
    display: flex;
    justify-content: space-between;
}
.ant-table-filter-column-title .LongTextDiv {
    max-width: 98px;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content {
    overflow: visible !important;
}
