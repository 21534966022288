@b: use-preferred-office;
.@{b} {
    &__tip {
        margin-bottom: 4px;
        line-height: 20px;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        text-align: left;
    }
    &__buildingList {
        // padding-left: 16px;
        max-height: 384px;
        overflow: auto;
        border: 1px solid rgba(0, 0, 0 ,0.13);
        border-radius: 4px;
    }
    &__buildName--disabled {
        .so-plan-lock-icon {
            padding: 0;
        }
    }
    &__desInfo {
        width: 100%;
        .@{b}__icon {
            padding-right: 4px;
            color: rgba(0, 0, 0, 0.38);
        }
        .@{b}__label {
            .ant-typography{
                line-height: 20px;
                font-weight: 400;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
    &__floorLabel {
        margin-bottom: 4px;
        line-height: 16px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        text-align: left;
    }
    &__defaultBuilding {
        position: relative;
        width: 144px;
        height: 96px;
        background-color: #e6f6ff;
        border-radius: 4px;
        .icon-font {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 25px;
            color: rgb(80, 173, 250);
        }
    }
}
