@b:personal-setting-modal;
.@{b} {
    &__groupItem {
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0;
        }
        .@{b}__groupTitle {
            margin-bottom: 12px;
            line-height: 24px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.87);
            & + .@{b}__formItem {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
            }
        }
        .@{b}__formItem {
            .@{b}__formItemLabel {
                margin-bottom: 4px;
                line-height: 16px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.6);
            }
            .ant-upload-picture-card-wrapper {
                width: 56px;
                height: 56px;
                .ant-upload-select-picture-card {
                    min-width: 56px;
                    width: 56px;
                    height: 56px;
                    border: none;
                    .upload-inner-mask {
                        display: none;
                    }
                }
                .upload-inner {
                    img {
                        width: 56px;
                        height: 56px;
                    }
                }
            }
            .@{b}__switchItem {
                padding: 12px 16px;

                .@{b}__switchItemLabel {
                    margin: 0;
                    line-height: 20px;
                    font-weight: 600;
                }
                .@{b}__switchItemTip {
                    margin: 0;
                    margin-top: 4px;
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.38);
                    line-height: 16px;
                }
            }
            .@{b}__changePwdBtn {
                border: 1px solid rgba(0, 0, 0, 0.13);
                box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
                border-radius: 6px;
            }
        }
    }
    &__groupBorder {
        .@{b}__formItem {
            border: 1px solid rgba(0, 0, 0, 0.13);
            &:first-child {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
            }
            &:last-child {
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }
            & + .@{b}__formItem {
                border-top: 0;
            }
            .ant-form-item {
                margin-bottom: 0;
            }
        }

    }
}
.@{b}__preferredDrop {
    .ant-select-tree-list-holder-inner{
        .ant-select-tree-treenode{
            .ant-select-tree-node-content-wrapper {
                overflow: hidden;
            }
            .ant-select-tree-title {
                >div {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    width: 100%;
                    overflow: hidden;
                }
            }
            &.ant-select-tree-treenode-disabled {
                .ant-select-tree-title {
                    color: rgba(0, 0, 0, 0.24);
                }
            }
        }
    }
}
